import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import Header from "../Navigation/Navigation"
import Footer from "../Footer/Footer"
const MainWrapper = () => {
    return (
        <>
        <Container fluid>
            <Row>
                <Col>
                <Header/>
                </Col>
                <Col md={12} className="p-0">
                <Outlet/>
                </Col>
                <Col md={12} className="p-0">
                <Footer/>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default MainWrapper