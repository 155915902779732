import React from "react"
import '../../assets/css/style.css'
import '../../assets/css/meanmenu.css'
import '../../assets/css/spacing.css'
import '../../assets/css/animate.css'
import '../../assets/css/backtotop.css'
import '../../assets/css/magnific-popup.css'
import '../../assets/css/nice-select.css'
import '../../assets/css/elegentfonts.css'
import '../../assets/css/ui-icon.css'
import '../../assets/css/nice-select.css'
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaBasketballBall } from "react-icons/fa"; 
import { FaWhatsapp } from "react-icons/fa"; 
import logoimage from "../../assets/img/logo/logo.png"
const Footer = () => {
    return (
        <>
        {/* footer-area */}
        <footer>
          <div className="footer-bg theme-bg bg-bottom" data-background="assets/img/bg/shape-bg-02.png">
            <div className="f-border pt-115 pb-70">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 col-md-6">
                    <div className="footer-widget footer-col-1 mb-50">
                      <div className="footer-widget__text mb-35">
                        <h3 className="footer-widget__title">Link</h3>
                      </div>
                      <div className="footer-widget__link">
                        <ul>
                          <li><a href="about">About us</a></li>
                          <li><a href="contact">Contact</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xl-3 col-md-4">
                    <div className="footer-widget footer-col-2 mb-50">
                      <div className="footer-widget__text mb-35">
                        <h3 className="footer-widget__title">Quick Links</h3>
                      </div>
                      <div className="footer-widget__link">
                        <ul>
                          <li><a href="#">Refund Policy</a></li>
                          <li><a href="#">Documentation</a></li>
                          <li><a href="#">Chat online</a></li>
                          <li><a href="#">Order Cancel</a></li>
                          <li><a href="#">Privacy Policy</a></li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-xl-3 col-md-4">
                    <div className="footer-widget footer-col-3 mb-50">
                      <div className="footer-widget__text mb-35">
                        <h3 className="footer-widget__title">Support</h3>
                      </div>
                      <div className="footer-widget__link">
                        <ul>
                          <li><a href="contact.html">Contact us</a></li>
                          <li><a href="#">Online Chat</a></li>
                          <li><a href="#">Whatsapp</a></li>
                          <li><a href="#">Telegram</a></li>
                          <li><a href="#">Ticketing</a></li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-xl-6 col-lg-6 col-md-8">
                    <div className="footer-widget footer-col-4  mb-50">
                      <div className="footer-widget__text mb-35">
                        <h3 className="footer-widget__title">Support</h3>
                      </div>
                      <p>"Unlock your tech potential with exclusive discounts on IT courses!  Elevate your skills with top-notch training and enjoy unparalleled support throughout your learning journey. Limited-time savings, limitless knowledge seize the opportunity!</p>
                      <div className="footer-widget__f-newsletter mb-40">
                        <form action="index.html">
                          <span><i className="icon_mail_alt" /></span>
                          <input type="email" placeholder="Enter your email" />
                          <button className="footer-widget__submit tp-border-btn2">Subscribe Now</button>
                        </form>
                      </div>
                      <div className="footer-widget__social d-flex align-items-center">
                        <a href="#"><FaFacebookF /></a>
                        <a href="#"><FaYoutube /></a>
                        <a href="#"><FaBasketballBall /></a>
                        <a href="#"><FaWhatsapp /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="f-copyright pt-60 pb-30">
              <div className="container">
                <div className="row">
                  <div className="col-md-5">
                    <div className="f-copyright__logo mb-30">
                      <a href="/"><img src={logoimage} alt="logo" style={{width:'100%', maxWidth:'200px'}} /></a>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="f-copyright__text text-md-end mb-30">
                      <span>Copyright©5bix , All Rights Reserved</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        </>
    )
}

export default Footer
