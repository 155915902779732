import React from "react"
import '../../assets/css/style.css'
import '../../assets/css/meanmenu.css'
import '../../assets/css/spacing.css'
import '../../assets/css/animate.css'
import '../../assets/css/backtotop.css'
import '../../assets/css/magnific-popup.css'
import '../../assets/css/nice-select.css'
import '../../assets/css/elegentfonts.css'
import '../../assets/css/ui-icon.css'
import '../../assets/css/nice-select.css'
const NodeCourse = () => {
    return (
        <>
            <div>
                <section className="breadcrumb__area include-bg pt-150 pb-150 breadcrumb__overlay" data-background="assets/img/breadcrumb/breadcrumb-bg-1.jpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title mb-20">Node Course</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="c-details-area pt-120 pb-50 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".2s">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="c-details-wrapper mr-25">
                                    <div className="course-details-content mb-45">
                                        <div className="tpcourse__category mb-15">
                                            <ul className="tpcourse__price-list d-flex align-items-center">
                                                <li><a className="c-color-yellow" href="#" style={{ fontSize: "50px" }}>Course List</a></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <div id="example">
                                                <ul class="list">
                                                    <li class="shopping py-4">
                                                        <span class="title">Introduction to NodeJS</span>
                                                    </li>
                                                    <li class="personal py-4">
                                                        <span class="title">Middleware in Nodejs</span>
                                                    </li>
                                                    <li class="work py-4">
                                                        <span class="title">Build nodejs applications with mongodb</span>
                                                    </li>
                                                    <li class="shopping py-4">
                                                        <span class="title">File system</span>
                                                    </li>
                                                    <li class="personal py-4">
                                                        <span class="title">Async/await</span>
                                                    </li>
                                                    <li class="work py-4">
                                                        <span class="title">How to front end</span>
                                                    </li>
                                                    <li class="shopping py-4">
                                                        <span class="title">Web development</span>
                                                    </li>
                                                    <li class="personal py-4">
                                                        <span class="title">Node package manager</span>
                                                    </li>
                                                    <li class="work py-4">
                                                        <span class="title">Install Node js</span>
                                                    </li>
                                                    <li class="shopping py-4">
                                                        <span class="title">Callbacks</span>
                                                    </li>
                                                    <li class="personal py-4">
                                                        <span class="title">Modules</span>
                                                    </li>
                                                    <li class="work py-4">
                                                        <span class="title">CORS in NodeJS</span>
                                                    </li>
                                                    <li class="shopping py-4">
                                                        <span class="title">JavaScript</span>
                                                    </li>
                                                    <li class="personal py-4">
                                                        <span class="title">Express</span>
                                                    </li>
                                                    <li class="work py-4">
                                                        <span class="title">Master NodeJS</span>
                                                    </li>
                                                    <li class="shopping py-4">
                                                        <span class="title">Databases</span>
                                                    </li>
                                                    <li class="personal py-4">
                                                        <span class="title">MongoDB</span>
                                                    </li>
                                                    <li class="work py-4">
                                                        <span class="title">Event loop</span>
                                                    </li>
                                                    <li class="shopping py-4">
                                                        <span class="title">JSON file</span>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default NodeCourse
