import React from "react"
import ReactDOM from "react-dom/client"
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import MainWrapper from "./Component/MainWrapper/MainWrapper"
import Landing from "./Component/LandingPage"
import About from "./Component/AboutPage"
import Contact from "./Component/Contact"
import NodeCourse from "./Component/Course/node"
import ReactCourse from "./Component/Course/react"
import FullStack from "./Component/Course/FullStack"
import WebDesign from "./Component/Course/WebDesign"
import Graphic from "./Component/Course/Graphic"
function App (){
  const router = createBrowserRouter([
    {
      path: "/",  // Specify the path for the MainWrapper route
      errorElement: <></>,
      element: <MainWrapper />,
      children: [
        {
          path: "/",  // Specify the path for the Landing route
          index: true,
          element: <Landing />,
        },
        {
          path: "/about",  // Specify the path for the About route
          element: <About/>,
        },
        {
          path: "/contact",  // Specify the path for the About route
          element: <Contact/>,
        },
        {
          path: "/react",  // Specify the path for the About route
          element: <ReactCourse/>,
        },
        {
          path: "/node",  // Specify the path for the About route
          element: <NodeCourse/>,
        },
        {
          path: "/full-stack",  // Specify the path for the About route
          element: <FullStack/>,
        },
        {
          path: "/webdesign",  // Specify the path for the About route
          element: <WebDesign/>,
        },
        {
          path: "/graphic",  // Specify the path for the About route
          element: <Graphic/>,
        },
        
      ],
    },
])
return (
  <RouterProvider router={router} />
)
}
export default App
