import React from "react"
import '../../assets/css/style.css'
import '../../assets/css/meanmenu.css'
import '../../assets/css/spacing.css'
import '../../assets/css/animate.css'
import '../../assets/css/backtotop.css'
import '../../assets/css/magnific-popup.css'
import '../../assets/css/nice-select.css'
import '../../assets/css/elegentfonts.css'
import '../../assets/css/ui-icon.css'
import '../../assets/css/nice-select.css'
import logoimage from "../../assets/img/logo/logo.png"
const Header = () => {
    return (
        <>
            <div>
                <meta charSet="utf-8" />
                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <title></title>
                <meta name="description" content />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="shortcut icon" type="image/x-icon" href="assets/img/favicon.png" />
                <div id="loading">
                    <div id="loading-center">
                        <div id="loading-center-absolute">
                        </div>
                    </div>
                </div>
                <div className="progress-wrap">
                    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                    </svg>
                </div>
                {/* header area start */}
                <header className="header__transparent ">
                    <div className="header__area">
                        <div className="main-header header-xy-spacing" id="header-sticky">
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-xxl-3 col-xl-3 col-lg-5 col-md-6 col-6">
                                        <div className="logo-area d-flex align-items-center">
                                            <div className="logo">
                                                <a href="/">
                                                    <img src={logoimage} alt="logo" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-9 col-xl-9 col-lg-7 col-md-6 col-6 d-flex align-items-center justify-content-end">
                                        <div className="main-menu d-flex justify-content-end m-auto">
                                            <nav id="mobile-menu">
                                                <ul>
                                                    <li className="has-dropdown">
                                                        <a href="/">Home</a>
                                                    </li>
                                                    <li className="has-dropdown">
                                                        <a href="/about">About</a>
                                                    </li>
                                                    <li className="has-dropdown">
                                                        <a href="/contact">Contact</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="header-right d-md-flex align-items-center">
                                            <div className="tp-banner-btn">
                                                <a href="contact" className="tp-btn">Contact Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    )
}

export default Header
