import React from "react"
import '../../assets/css/style.css'
import '../../assets/css/meanmenu.css'
import '../../assets/css/spacing.css'
import '../../assets/css/animate.css'
import '../../assets/css/backtotop.css'
import '../../assets/css/magnific-popup.css'
import '../../assets/css/nice-select.css'
import '../../assets/css/elegentfonts.css'
import '../../assets/css/ui-icon.css'
import '../../assets/css/nice-select.css' 
import contactsub from "../../assets/img/bg/contact-sub-bg-01.png"
import { FaPhoneAlt } from "react-icons/fa"; 
import { FaLocationPin } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
const Contact = () => {
    return (
        <>
            <main>
                <section className="breadcrumb__area include-bg pt-150 pb-150 breadcrumb__overlay" data-background="assets/img/breadcrumb/breadcrumb-bg-1.jpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title py-5 text-center">Contact Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="location-area pt-120 pb-85 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".2s">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-md-6">
                                <div className="location-item text-center mb-30 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".2s">
                                    <div className="location-icon mb-25">
                                    <FaPhoneAlt />
                                    </div>
                                    <div className="location-content">
                                        <h5 className="location-title"><a href="tell:(209)555-0104">(209) 555-0104</a></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="location-item text-center mb-30 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".4s">
                                    <div className="location-icon mb-25">
                                    <FaLocationPin />
                                    </div>
                                    <div className="location-content">
                                        <h5 className="location-title">C 201-202 (B), Industrial Area Phase 8B, Mohali</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="location-item text-center mb-30 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".6s">
                                    <div className="location-icon mb-25">
                                    <CiMail />
                                    </div>
                                    <div className="location-content">
                                        <h5 className="location-title"><a href="mailto:trainingbix@gmail.com">trainingbix@gmail.com</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contact-area pb-60 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".2s">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="contact-wrapper mr-65 mb-60">
                                    <div className="sub-contact-title">
                                        <h5 className="contact-title mb-30">Send Us Message</h5>
                                    </div>
                                    <div className="contact-form">
                                        <form id="contact-form" action="assets/mail.php" method="POST">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="contact-form-input mb-25">
                                                        <span>Name</span>
                                                        <input type="name" placeholder="Your Name" name="name" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="contact-form-input mb-25">
                                                        <span>Email Id</span>
                                                        <input type="email" placeholder="Your Email" name="email" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="contact-form-input mb-40">
                                                        <span>Comments</span>
                                                        <textarea placeholder="Enter Your Message" name="message" required defaultValue={""} />
                                                    </div>
                                                    <button className="tp-btn" type="submit">Submit Now</button>
                                                </div>
                                            </div>
                                        </form>
                                        <p className="ajax-response" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                                <div className="contact-bg mb-60">
                                    <img src={contactsub} alt="contact-bg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="map-area wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".2s">
                    <div className="map-wrapper">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d16421992.915334428!2d-74.54900526175051!3d53.308708074896664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1654426516184!5m2!1sen!2sbd" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                </div>
            </main>
        </>
    )
}

export default Contact
