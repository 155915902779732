import React from "react"
import '../../assets/css/style.css'
import '../../assets/css/meanmenu.css'
import '../../assets/css/spacing.css'
import '../../assets/css/animate.css'
import '../../assets/css/backtotop.css'
import '../../assets/css/magnific-popup.css'
import '../../assets/css/nice-select.css'
import '../../assets/css/elegentfonts.css'
import '../../assets/css/ui-icon.css'
import '../../assets/css/nice-select.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import bannerimg from '../../assets/img/banner/banner-shape-01.png'
import aboutimage from "../../assets/img/about/about-img.png"
import shape03 from "../../assets/img/about/about-shape-03.png"
import aboutshape04 from "../../assets/img/about/about-shape-04.png"
import aboutshape01 from "../../assets/img/about/about-shape-01.png"
import aboutshape05 from "../../assets/img/about/about-shape-05.png"
import aboutshape02 from "../../assets/img/about/about-shape-02.png"
import category01 from "../../assets/img/category/category-01.png"
import category02 from "../../assets/img/category/category-02.png"
import category03 from "../../assets/img/category/category-03.png"
import category04 from "../../assets/img/category/category-04.png"
import category05 from "../../assets/img/category/category-05.png"
import category06 from "../../assets/img/category/category-06.png"
import category07 from "../../assets/img/category/category-07.png"
import category08 from "../../assets/img/category/category-08.png"
import coursethumb01 from "../../assets/img/course/course-thumb-01.jpg"
import courseavata01 from "../../assets/img/course/c-details-ava-01.png"
import cmeta01 from "../../assets/img/icon/c-meta-01.png"
import cmeta02 from "../../assets/img/icon/c-meta-02.png"
import cmeta03 from "../../assets/img/icon/c-meta-03.png"
import coursethumb02 from "../../assets/img/course/course-thumb-02.jpg"
import courseavata02 from "../../assets/img/course/course-avata-02.png"
import coursethumb03 from "../../assets/img/course/course-thumb-03.jpg"
import courseavata03 from "../../assets/img/course/course-avata-03.png"
import coursethumb04 from "../../assets/img/course/course-thumb-04.jpg"
import courseavata04 from "../../assets/img/course/course-avata-04.png"
import coursethumb05 from "../../assets/img/course/course-thumb-05.jpg"
import courseavata05 from "../../assets/img/course/course-avata-05.png"
import coursethumb06 from "../../assets/img/course/course-thumb-06.jpg"
import courseavata06 from "../../assets/img/course/course-avata-06.png"
import chooseimg01 from "../../assets/img/bg/choose-img-01.jpg"
import { FaCheck } from "react-icons/fa6";
import instructorbg from "../../assets/img/bg/instructor-bg-01.jpg"
import testava from "../../assets/img/icon/test-ava-02.png"
import { ImFacebook } from "react-icons/im";
import { ImTwitter } from "react-icons/im";
import { ImInstagram } from "react-icons/im";
import { ImYoutube } from "react-icons/im"; 
import { FaReact } from "react-icons/fa"; 
import { FaNodeJs } from "react-icons/fa"; 
import { SiXdadevelopers } from "react-icons/si"; 
import { MdComputer } from "react-icons/md"; 
import { FaDraftingCompass } from "react-icons/fa";
const Landing = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,  // Add this line to enable autoplay
    autoplaySpeed: 2000,  // Optional: Set autoplay speed in milliseconds
  };
  return (
    <>
      <div>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <title>Epora - Online Courses &amp; Education HTML Template</title>
        <meta name="description" content />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Place favicon.ico in the root directory */}
        <link rel="shortcut icon" type="image/x-icon" href="assets/img/favicon.png" />
        {/* CSS here */}
        <link rel="stylesheet" href="assets/css/nice-select.css" />
        {/*[if lte IE 9]>
      <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
      <![endif]*/}
        {/* pre loader area start */}
        <div id="loading">
          <div id="loading-center">
            <div id="loading-center-absolute">
              {/* loading content here */}
            </div>
          </div>
        </div>
        {/* pre loader area end */}
        {/* back to top start */}
        <div className="progress-wrap">
          <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
        {/* back to top end */}
        {/* header area start */}
        {/* header area end */}
        <div className="tp-sidebar-menu">
          <button className="sidebar-close"><i className="icon_close" /></button>
          <div className="side-logo mb-30">
            <a href="index.html"><img src="assets/img/logo/logo-black.png" alt="logo" /></a>
          </div>
          <div className="mobile-menu" />
          <div className="sidebar-info">
            <h4 className="mb-15">Contact Info</h4>
            <ul className="side_circle">
              <li>27 Division St, New York</li>
              <li><a href="tel:123456789">+1 800 123 456 78</a></li>
              <li><a href="mailto:epora@example.com">epora@example.com</a></li>
            </ul>
            <div className="side-social">
              <a href="#"><i className="fab fa-facebook-f" /></a>
              <a href="#"><i className="fab fa-twitter" /></a>
              <a href="#"><i className="fab fa-linkedin" /></a>
              <a href="#"><i className="fab fa-instagram" /></a>
            </div>
          </div>
        </div>
        <div className="body-overlay" />
        <main>
          {/* banner-area */}
          <section className="banner-area fix p-relative">
            <div className="banner-bg">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-8">
                    <div className="hero-content">
                      <span>Learn New Skills &amp; Popular Courses</span>
                      <h2 className="hero-title mb-35">Unlock Your Potential<i>Learn, Grow and Succeed</i>.</h2>
                      <p>Empowering Minds Offline: Knowledge Beyond Boundaries</p>
                      <div className="tp-banner-btn">
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <div className="banner-info d-none">
                      <ul>
                        <li><span>235K</span>Worldwide Students</li>
                        <li><span>3.5K</span>Free Pro Courses</li>
                        <li><span>4.7<i className="fi fi-rr-star " /></span>Worldwide Review</li>
                      </ul>
                    </div>
                  </div>
                  <div className="banner-shape d-none d-lg-block">
                    <img src={bannerimg} className="b-shape" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* banner-area-end */}
          {/* feature-area */}
          <section className="tp-feature-area grey-bg pt-115 pb-90 pl-205 pr-205 bg-bottom" data-background="assets/img/bg/shape-bg-1.png">
            <div className="container-fluid">
              <div className="row text-center">
                <div className="col-lg-12">
                  <div className="section-title mb-60">
                    <span className="tp-sub-title mb-20">What We Offer</span>
                    <h2 className="tp-section-title">For Your Future Learning.</h2>
                  </div>
                </div>
              </div>
              <div className="tp-feature-cn">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="tpfea mb-30 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".4s">
                      <div className="tpfea__icon mb-25">
                        <i className="fi fi-rr-user" />
                      </div>
                      <div className="tpfea__text">
                        <h5 className="tpfea__title mb-20">Expert Trainer</h5>
                        <p>Elevate your skills with guidance from our Expert Trainer – Where Mastery Meets Mentorship.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="tpfea mb-30 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".5s">
                      <div className="tpfea__icon mb-25">
                        <i className="fi fi-rr-document" />
                      </div>
                      <div className="tpfea__text">
                        <h5 className="tpfea__title mb-20">Get Certificate</h5>
                        <p>Validate Your Achievement: Our Certificate Your Stamp <br/>of Excellence.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* feature-area-end */}
          {/* about-area */}
          <section className="tp-about-area pt-120 pb-90 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xxl-7 col-xl-6 col-lg-6 col-md-6">
                  <div className="tp-about-img p-relative pb-30 ml-10">
                    <img src={aboutimage} alt="about-img" />
                    <div className="tp-about-line-shape d-none d-md-block">
                      <img src={shape03} alt="about-shape" className="tp-aline-one" />
                      <img src={aboutshape04} alt="about-shape" className="tp-aline-two" />
                      <img src={aboutshape05} alt="about-shape" className="tp-aline-three" />
                    </div>
                    <div className="tp-about-shape  d-none d-xl-block">
                      <img src={aboutshape01} alt="about-shape" className="a-shape-one" />
                      <img src={aboutshape02} alt="about-shape" className="a-shape-two" />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6">
                  <div className="tp-about-content pb-30 ml-80">
                    <div className="section-title mb-55">
                      <span className="tp-sub-title mb-20">About Our Courses</span>
                      <h2 className="tp-section-title mb-15">Mastering the Digital Landscape: Navigated IT Training Courses with Precision and Expertise</h2>
                      <p>Elevate your expertise in our dynamic IT training classes – where knowledge meets innovation! Dive into a world of cutting-edge skills, expert guidance, and hands-on experience. Join us on the path to mastery and redefine your IT journey. Let's code the future together!" </p>
                    </div>
                    <div className="about-btn">
                      <a href="about.html" className="tp-btn">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* about-area-end */}
          {/* catrgory-area */}
          <section className="background-shape">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-12">
                  <div className="section-title mb-65">
                    <h2 className="tp-section-title">Categories</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="tp-cat-item mb-40 d-flex align-items-center">
                    <div className="tp-category-icon mr-15">
                    <FaReact />
                    </div>
                    <h4 className="tp-category-title"><a href="react">React</a></h4>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="tp-cat-item mb-40 d-flex align-items-center">
                    <div className="tp-category-icon mr-15">
                    <FaNodeJs />
                    </div>
                    <h4 className="tp-category-title"><a href="node">Node</a></h4>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="tp-cat-item mb-40 d-flex align-items-center">
                    <div className="tp-category-icon mr-15">
                    <SiXdadevelopers />
                    </div>
                    <h4 className="tp-category-title"><a href="full-stack">Full stack</a></h4>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="tp-cat-item mb-40 d-flex align-items-center">
                    <div className="tp-category-icon mr-15">
                    <MdComputer />
                    </div>
                    <h4 className="tp-category-title"><a href="webdesign">Web Designing</a></h4>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="tp-cat-item mb-40 d-flex align-items-center">
                    <div className="tp-category-icon mr-15">
                    <FaDraftingCompass />
                    </div>
                    <h4 className="tp-category-title"><a href="graphic">Graphic</a></h4>
                  </div>
                </div>
              </div>
            </div>
        </section>
        {/* catrgory-area-end */}
        {/* course-area */}
        {/* <section className="course-area pt-115 pb-110 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".4s">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title mb-65">
                  <h2 className="tp-section-title mb-20">Explore Popular Courses</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="tpcourse mb-40">
                  <div className="tpcourse__thumb p-relative w-img fix">
                    <a href="course-details.html"><img src={coursethumb01} alt="course-thumb" /></a>
                    <div className="tpcourse__tag">
                      <a href="course-details.html"><i className="fi fi-rr-heart" /></a>
                    </div>
                  </div>
                  <div className="tpcourse__content">
                    <div className="tpcourse__avatar d-flex align-items-center mb-20">
                      <img src={courseavata01} alt="course-avata" />
                      <h4 className="tpcourse__title"><a href="course-details.html">The Complete 2022 Web Development Camp</a></h4>
                    </div>
                    <div className="tpcourse__meta pb-15 mb-20">
                      <ul className="d-flex align-items-center">
                        <li><img src={cmeta01} alt="meta-icon" /> <span>35 Classes</span></li>
                        <li><img src={cmeta02} alt="meta-icon" /> <span>291 Students</span></li>
                        <li><img src={cmeta03} alt="meta-icon" /> <span>4.7</span></li>
                      </ul>
                    </div>
                    <div className="tpcourse__category d-flex align-items-center justify-content-between">
                      <ul className="tpcourse__price-list d-flex align-items-center">
                        <li><a href="course-details.html">Design</a></li>
                        <li><a href="course-details.html">Development</a></li>
                      </ul>
                      <h5 className="tpcourse__course-price">$29.99</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="tpcourse mb-40">
                  <div className="tpcourse__thumb p-relative w-img fix">
                    <a href="course-details.html"><img src={coursethumb02} alt="course-thumb" /></a>
                    <div className="tpcourse__tag">
                      <a href="course-details.html"><i className="fi fi-rr-heart" /></a>
                    </div>
                  </div>
                  <div className="tpcourse__content">
                    <div className="tpcourse__avatar d-flex align-items-center mb-20">
                      <img src={courseavata02} alt="course-avata" />
                      <h4 className="tpcourse__title"><a href="course-details.html">Complete Web Design from Figma to Webflow</a></h4>
                    </div>
                    <div className="tpcourse__meta pb-15 mb-20">
                      <ul className="d-flex align-items-center">
                        <li><img src={cmeta01} alt="meta-icon" /> <span>35 Classes</span></li>
                        <li><img src={cmeta02} alt="meta-icon" /> <span>291 Students</span></li>
                        <li><img src={cmeta03} alt="meta-icon" /> <span>4.7</span></li>
                      </ul>
                    </div>
                    <div className="tpcourse__category d-flex align-items-center justify-content-between">
                      <ul className="tpcourse__price-list d-flex align-items-center">
                        <li><a href="course-details.html">Design</a></li>
                        <li><a href="course-details.html">Development</a></li>
                      </ul>
                      <h5 className="tpcourse__course-price">$29.99</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="tpcourse mb-40">
                  <div className="tpcourse__thumb p-relative w-img fix">
                    <a href="course-details.html"><img src={coursethumb03} alt="course-thumb" /></a>
                    <div className="tpcourse__tag">
                      <a href="course-details.html"><i className="fi fi-rr-heart" /></a>
                    </div>
                  </div>
                  <div className="tpcourse__content">
                    <div className="tpcourse__avatar d-flex align-items-center mb-20">
                      <img src={courseavata03} alt="course-avata" />
                      <h4 className="tpcourse__title"><a href="course-details.html">SEO: Structured Data Markup for Web Masters</a></h4>
                    </div>
                    <div className="tpcourse__meta pb-15 mb-20">
                      <ul className="d-flex align-items-center">
                        <li><img src={cmeta01} alt="meta-icon" /> <span>35 Classes</span></li>
                        <li><img src={cmeta02} alt="meta-icon" /> <span>291 Students</span></li>
                        <li><img src={cmeta03} alt="meta-icon" /> <span>4.7</span></li>
                      </ul>
                    </div>
                    <div className="tpcourse__category d-flex align-items-center justify-content-between">
                      <ul className="tpcourse__price-list d-flex align-items-center">
                        <li><a href="course-details.html">Design</a></li>
                        <li><a href="course-details.html">Development</a></li>
                      </ul>
                      <h5 className="tpcourse__course-price">$29.99</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="tpcourse mb-40">
                  <div className="tpcourse__thumb p-relative w-img fix">
                    <a href="course-details.html"><img src={coursethumb04} alt="course-thumb" /></a>
                    <div className="tpcourse__tag">
                      <a href="course-details.html"><i className="fi fi-rr-heart" /></a>
                    </div>
                  </div>
                  <div className="tpcourse__content">
                    <div className="tpcourse__avatar d-flex align-items-center mb-20">
                      <img src={courseavata04} alt="course-avata" />
                      <h4 className="tpcourse__title"><a href="course-details.html">Complete Web Design from Figma Freelancing</a></h4>
                    </div>
                    <div className="tpcourse__meta pb-15 mb-20">
                      <ul className="d-flex align-items-center">
                        <li><img src={cmeta01} alt="meta-icon" /> <span>35 Classes</span></li>
                        <li><img src={cmeta02} alt="meta-icon" /> <span>291 Students</span></li>
                        <li><img src={cmeta03} alt="meta-icon" /> <span>4.7</span></li>
                      </ul>
                    </div>
                    <div className="tpcourse__category d-flex align-items-center justify-content-between">
                      <ul className="tpcourse__price-list d-flex align-items-center">
                        <li><a href="course-details.html">Design</a></li>
                        <li><a href="course-details.html">Development</a></li>
                      </ul>
                      <h5 className="tpcourse__course-price">$29.99</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="tpcourse mb-40">
                  <div className="tpcourse__thumb p-relative w-img fix">
                    <a href="course-details.html"><img src={coursethumb05} alt="course-thumb" /></a>
                    <div className="tpcourse__tag">
                      <a href="course-details.html"><i className="fi fi-rr-heart" /></a>
                    </div>
                  </div>
                  <div className="tpcourse__content">
                    <div className="tpcourse__avatar d-flex align-items-center mb-20">
                      <img src={courseavata05} alt="course-avata" />
                      <h4 className="tpcourse__title"><a href="course-details.html">Dreamweaver - Coding your first website using</a></h4>
                    </div>
                    <div className="tpcourse__meta pb-15 mb-20">
                      <ul className="d-flex align-items-center">
                        <li><img src={cmeta01} alt="meta-icon" /> <span>35 Classes</span></li>
                        <li><img src={cmeta02} alt="meta-icon" /> <span>291 Students</span></li>
                        <li><img src={cmeta03} alt="meta-icon" /> <span>4.7</span></li>
                      </ul>
                    </div>
                    <div className="tpcourse__category d-flex align-items-center justify-content-between">
                      <ul className="tpcourse__price-list d-flex align-items-center">
                        <li><a href="course-details.html">Design</a></li>
                        <li><a href="course-details.html">Development</a></li>
                      </ul>
                      <h5 className="tpcourse__course-price">$29.99</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="tpcourse mb-40">
                  <div className="tpcourse__thumb p-relative w-img fix">
                    <a href="course-details.html"><img src={coursethumb06} alt="course-thumb" /></a>
                    <div className="tpcourse__tag">
                      <a href="course-details.html"><i className="fi fi-rr-heart" /></a>
                    </div>
                  </div>
                  <div className="tpcourse__content">
                    <div className="tpcourse__avatar d-flex align-items-center mb-20">
                      <img src={courseavata06} alt="course-avata" />
                      <h4 className="tpcourse__title"><a href="course-details.html">How to Write Great Web Content - Better Search!</a></h4>
                    </div>
                    <div className="tpcourse__meta pb-15 mb-20">
                      <ul className="d-flex align-items-center">
                        <li><img src={cmeta01} alt="meta-icon" /> <span>35 Classes</span></li>
                        <li><img src={cmeta02} alt="meta-icon" /> <span>291 Students</span></li>
                        <li><img src={cmeta03} alt="meta-icon" /> <span>4.7</span></li>
                      </ul>
                    </div>
                    <div className="tpcourse__category d-flex align-items-center justify-content-between">
                      <ul className="tpcourse__price-list d-flex align-items-center">
                        <li><a href="course-details.html">Design</a></li>
                        <li><a href="course-details.html">Development</a></li>
                      </ul>
                      <h5 className="tpcourse__course-price">$29.99</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="course-btn mt-20"><a className="tp-btn" href="course-details.html">Browse All Courses</a></div>
              </div>
            </div>
          </div>
        </section> */}
        {/* course-area-end */}
        {/* choose-area */}
        <section className="choose-area pb-90 wow fadeInUp py-5" data-wow-duration=".8s" data-wow-delay=".4s">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-6 col-md-6">
                <div className="tp-choose-img p-relative mb-30 ml-25">
                  <img src={chooseimg01} alt="choose-img" />
                  <div className="tpchoose-img-text d-none d-md-block">
                    <ul>
                      <li>
                        <i>23+</i>
                        <p>Years Experiences</p>
                      </li>
                      <li>
                        <FaCheck className="fa-light fa-check" />
                        <p>Fully Safe &amp; Secure</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="tp-choose-content mb-30">
                  <div className="section-title mb-25">
                    <span className="tp-sub-title mb-25">Why Choose Us</span>
                    <h2 className="tp-section-title mb-20">Why You Choose Our<br />Bix Training Online learning</h2>
                    <p>"Choose BIX Training for a Tech Odyssey like no other! Unleash your potential with courses tailored to industry demands. Elevate your skills, conquer challenges, and redefine your future in the world of IT. Join us on a transformative learning experience – where excellence meets innovation.</p>
                  </div>
                  <div className="tp-choose-list mb-35">
                    <ul>
                      <li><i className="fa-light fa-check" />Increasing Your Learning Skills</li>
                      <li><i className="fa-light fa-check" />Finish Your Course, Get Certificate</li>
                    </ul>
                  </div>
                  <div className="choose-btn">
                    <a href="about.html" className="tp-btn">Explore Courses</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* choose-area-end */}
        {/* instructor-area */}
        {/* <section className="instructor-area pt-110 pb-110 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-8 col-md-7 col-12">
                <div className="section-title mb-65">
                  <h2 className="tp-section-title mb-20">Our Expert Instructor</h2>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4 col-md-5 col-6">
                <div className="tp-instruc-arrow d-flex justify-content-md-end" />
              </div>
            </div>
            <Slider {...settings}>
              <div>
                <div className="tp-instruc-item">
                  <div className="tp-instructor text-center p-relative mb-30">
                    <div className="tp-instructor__thumb mb-20">
                      <img src={instructorbg} alt="instructor-profile" />
                    </div>
                    <div className="tp-instructor__content">
                      <h4 className="tp-instructor__title mb-20"><a href="instructor-profile.html">Devon Lane</a></h4>
                      <span>Instructor</span>
                      <div className="tp-instructor__social">
                        <ul>
                          <li><a href="instructor-profile.html"><ImFacebook /></a></li>
                          <li><a href="instructor-profile.html"><ImTwitter /></a></li>
                          <li><a href="instructor-profile.html"><ImInstagram /></a></li>
                          <li><a href="instructor-profile.html"><ImYoutube /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="tp-instruc-item">
                  <div className="tp-instructor text-center p-relative mb-30">
                    <div className="tp-instructor__thumb mb-20">
                      <img src={instructorbg} alt="instructor-profile" />
                    </div>
                    <div className="tp-instructor__content">
                      <h4 className="tp-instructor__title mb-20"><a href="instructor-profile.html">Devon Lane</a></h4>
                      <span>Instructor</span>
                      <div className="tp-instructor__social">
                        <ul>
                          <li><a href="instructor-profile.html"><ImFacebook /></a></li>
                          <li><a href="instructor-profile.html"><ImTwitter /></a></li>
                          <li><a href="instructor-profile.html"><ImInstagram /></a></li>
                          <li><a href="instructor-profile.html"><ImYoutube /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="tp-instruc-item">
                  <div className="tp-instructor text-center p-relative mb-30">
                    <div className="tp-instructor__thumb mb-20">
                      <img src={instructorbg} alt="instructor-profile" />
                    </div>
                    <div className="tp-instructor__content">
                      <h4 className="tp-instructor__title mb-20"><a href="instructor-profile.html">Devon Lane</a></h4>
                      <span>Instructor</span>
                      <div className="tp-instructor__social">
                        <ul>
                          <li><a href="instructor-profile.html"><ImFacebook /></a></li>
                          <li><a href="instructor-profile.html"><ImTwitter /></a></li>
                          <li><a href="instructor-profile.html"><ImInstagram /></a></li>
                          <li><a href="instructor-profile.html"><ImYoutube /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="tp-instruc-item">
                  <div className="tp-instructor text-center p-relative mb-30">
                    <div className="tp-instructor__thumb mb-20">
                      <img src={instructorbg} alt="instructor-profile" />
                    </div>
                    <div className="tp-instructor__content">
                      <h4 className="tp-instructor__title mb-20"><a href="instructor-profile.html">Devon Lane</a></h4>
                      <span>Instructor</span>
                      <div className="tp-instructor__social">
                        <ul>
                          <li><a href="instructor-profile.html"><ImFacebook /></a></li>
                          <li><a href="instructor-profile.html"><ImTwitter /></a></li>
                          <li><a href="instructor-profile.html"><ImInstagram /></a></li>
                          <li><a href="instructor-profile.html"><ImYoutube /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            <div className="row">
              <div className="col-lg-12">
                <div className="instructor-btn text-center">
                  <a className="tp-btn" href="instructor.html">All Instructor</a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* instructor-area-enfd */}
        {/* testimonial-area */}
        {/* <section className="testimonial-area bg-bottom pt-110 pb-90 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s" data-background="assets/img/bg/shape-bg-1.png">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-6 col-lg-8 col-md-8 col-12">
                <div className="section-title mb-65">
                  <h2 className="tp-section-title mb-20">Our Expert Instructor</h2>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4 col-md-4 col-6">
                <div className="tp-section-arrow d-flex justify-content-md-end mb-40" />
              </div>
            </div>
            <div className="testimonial-active tp-slide-space">
              <Slider {...settings}>
                <div>
                  <div className="tp-test-slide-item">
                    <div className="tp-testi p-relative mt-65">
                      <div className="tp-testi__avatar">
                        <img src={testava} alt="testi-avatar" />
                      </div>
                      <div className="tp-testi__rating pb-5">
                        <ul className="d-flex align-items-center justify-content-end mr-5 mb-25">
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-rs-star" /></li>
                        </ul>
                      </div>
                      <div className="tp-testi__avainfo">
                        <p>BIX Training offers meticulously crafted courses that cover a wide spectrum of topics, providing a thorough understanding of current industry trends in IT.</p>
                        <h3 className="tp-testi__title">Jenny Wilson</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="tp-test-slide-item">
                    <div className="tp-testi p-relative mt-65">
                      <div className="tp-testi__avatar">
                        <img src={testava} alt="testi-avatar" />
                      </div>
                      <div className="tp-testi__rating pb-5">
                        <ul className="d-flex align-items-center justify-content-end mr-5 mb-25">
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-rs-star" /></li>
                        </ul>
                      </div>
                      <div className="tp-testi__avainfo">
                        <p>The instructors at BIX Training are not only experts in their field but also passionate mentors. Their enthusiasm for teaching translates into a more engaging learning experience.</p>
                        <h3 className="tp-testi__title">Jenny Wilson</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="tp-test-slide-item">
                    <div className="tp-testi p-relative mt-65">
                      <div className="tp-testi__avatar">
                        <img src={testava} alt="testi-avatar" />
                      </div>
                      <div className="tp-testi__rating pb-5">
                        <ul className="d-flex align-items-center justify-content-end mr-5 mb-25">
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-rs-star" /></li>
                        </ul>
                      </div>
                      <div className="tp-testi__avainfo">
                        <p>BIX Training provides a wide range of exposure in field of IT , providing every aspects of IT one can grab ,they welcome us with a smile and make us achieve our full potential </p>
                        <h3 className="tp-testi__title">Jenny Wilson</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="tp-test-slide-item">
                    <div className="tp-testi p-relative mt-65">
                      <div className="tp-testi__avatar">
                        <img src={testava} alt="testi-avatar" />
                      </div>
                      <div className="tp-testi__rating pb-5">
                        <ul className="d-flex align-items-center justify-content-end mr-5 mb-25">
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-ss-star" /></li>
                          <li><i className="fi fi-rs-star" /></li>
                        </ul>
                      </div>
                      <div className="tp-testi__avainfo">
                        <p>BIX Training offers meticulously crafted courses that cover a wide spectrum of topics, providing a thorough understanding of current industry trends in IT.</p>
                        <h3 className="tp-testi__title">Jenny Wilson</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section> */}
        {/* testimonial-area-end */}
      </main>
      {/* footer-area-end */}
      {/* JS here */}
    </div >
    </>
  )
}

export default Landing
