import React from "react"
import '../../assets/css/style.css'
import '../../assets/css/meanmenu.css'
import '../../assets/css/spacing.css'
import '../../assets/css/animate.css'
import '../../assets/css/backtotop.css'
import '../../assets/css/magnific-popup.css'
import '../../assets/css/nice-select.css'
import '../../assets/css/elegentfonts.css'
import '../../assets/css/ui-icon.css'
import '../../assets/css/nice-select.css'
import aboutsbg from "../../assets/img/about/about-sub-bg-01.png"
import chooseimg from "../../assets/img/bg/choose-2-img.jpg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import testavata from "../../assets/img/icon/testi-ava-sub-1.png"
import { FaQuoteRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import chooseshape from "../../assets/img/icon/choose-2-shape.png"
const About = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768, // Adjust this value based on your design
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <main>
                <section className="breadcrumb__area include-bg pt-150 pb-150 breadcrumb__overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title text-center py-5">About Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="feature-area pt-120 pb-90">
                    <div className="container">
                        <div className="tp-feature-cn">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="tpfea tp-feature-item text-center mb-30 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".5s">
                                        <div className="tpfea__icon mb-25">
                                            <i className="fi fi-rr-user" />
                                        </div>
                                        <div className="tpfea__text">
                                            <h5 className="tpfea__title mb-5">Expert Trainer</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="tpfea tp-feature-item text-center mb-30 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".7s">
                                        <div className="tpfea__icon mb-25">
                                            <i className="fi fi-rr-document" />
                                        </div>
                                        <div className="tpfea__text">
                                            <h5 className="tpfea__title mb-5">Get Certificate</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="tp-about-area pb-70 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".3s">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-12 col-12">
                                <div className="tp-about-class p-relative pb-50">
                                    <img src={aboutsbg} alt="about-img" />
                                    <div className="tp-about-class-info tp-sub-about-info d-none d-md-block">
                                        <ul>
                                            <li><span>235K</span>Worldwide Students</li>
                                            <li><span>4.7<i className="fi fi-ss-star" /></span>Worldwide Review</li>
                                            <li><span>3.5K</span>Free Pro Courses</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-12 col-12">
                                <div className="tp-about-class-content mb-50 ml-75">
                                    <div className="section-title mb-35">
                                        <span className="tp-sub-title-box mb-15">About Us</span>
                                        <h2 className="tp-section-title mb-25">Explore Thousands of Creative Classes.</h2>
                                        <p>Uncover a myriad of creative classes waiting for exploration. Delve into a diverse array of imaginative learning opportunities. Expand your horizons with an abundance of unique courses.</p>
                                    </div>
                                    <div className="tp-about-list mb-65">
                                        <ul>
                                            <li>
                                                <FaCheck />Choose Your Perfect Courses
                                            </li>
                                            <li>
                                                <FaCheck />After Finished Courses, Get Certificate
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="choose-area bg-bottom pb-120 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".3s">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-5 col-lg-6 col-md-12">
                                <div className="tp-choose-content mb-30">
                                    <div className="section-title mb-25">
                                        <span className="tp-sub-title-box mb-15">Why Choose Us</span>
                                        <h2 className="tp-section-title mb-20">Why You Choose Our Bix Training</h2>
                                        <p>Choose our Bix Training for its comprehensive curriculum tailored to your needs. Benefit from experienced instructors dedicated to your success. Join a supportive community committed to helping you achieve your goals.</p>
                                    </div>
                                    <div className="tp-choose-list tp-choose-bg mb-60">
                                        <ul>
                                            <li>
                                                <div className="tp-list-bg choose-sub-color">
                                                    <FaCheck />Increasing Your Learning Skills
                                                </div>
                                            </li>
                                            <li>
                                                <div className="tp-list-bg choose-sub-color">
                                                    <FaCheck />Finish Your Course, Get Certificate
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6 col-md-12">
                                <div className="tp-choose-img tp-choose-img-2 p-relative mb-30 mr-50 text-end">
                                    <img src={chooseimg} alt="choose-img" />
                                    <div className="tpchoose-img-text tp-chose-shape d-none d-md-block">
                                        <ul>
                                            <li>
                                                <i>23+</i>
                                                <p>Years Experiences</p>
                                            </li>
                                            <li>
                                                <FaCheck />
                                                <p>Fully Safe &amp; Secure</p>
                                            </li>
                                            <li>
                                                <p>Fully Safe &amp; Secure</p>
                                                <img src={chooseshape} alt="choose-shape" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="testimonial-area bg-bottom pt-120 pb-90 wow fadeInUp" data-wow-duration=".8s" data-wow-delay=".3s" data-background="assets/img/bg/shape-bg-1.png">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb-65">
                                    <span className="tp-sub-title-box mb-15">Testimonial</span>
                                    <h2 className="tp-section-title mb-20">What Our Happy Users Says</h2>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-active-box tp-slide-space-white">
                            <Slider {...settings}>
                                <div>
                                    <div className="tp-test-s-item">
                                        <div className="tp-testi tp-testi-round p-relative">
                                            <div className="tp-testi__ava testi-ava-border d-flex align-items-center mb-20 pb-20">
                                                <img src={testavata} alt="testi-avatar" />
                                                <div className="tp-testi__avainfo ml-20">
                                                    <h3 className="tp-testi__title tp-title-meta mt-5">Darrell Steward</h3>
                                                    <i>President of Sales</i>
                                                </div>
                                            </div>
                                            <p>Phosfluorescently aggregate bleeding-edge channels rather than global mindshare. Monotonectally enable enterprise wide strategi theme areas for worldwide initiatives.</p>
                                            <div className="tp-testi__rating mb-5">
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-rs-star" />
                                                <span>4.5</span>
                                            </div>
                                            <div className="testi-quote">
                                                <FaQuoteRight />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tp-test-s-item">
                                        <div className="tp-testi tp-testi-round p-relative">
                                            <div className="tp-testi__ava testi-ava-border d-flex align-items-center mb-20 pb-20">
                                                <img src={testavata} alt="testi-avatar" />
                                                <div className="tp-testi__avainfo ml-20">
                                                    <h3 className="tp-testi__title tp-title-meta mt-5">Darrell Steward</h3>
                                                    <i>President of Sales</i>
                                                </div>
                                            </div>
                                            <p>Phosfluorescently aggregate bleeding-edge channels rather than global mindshare. Monotonectally enable enterprise wide strategi theme areas for worldwide initiatives.</p>
                                            <div className="tp-testi__rating mb-5">
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-rs-star" />
                                                <span>4.5</span>
                                            </div>
                                            <div className="testi-quote">
                                                <FaQuoteRight />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tp-test-s-item">
                                        <div className="tp-testi tp-testi-round p-relative">
                                            <div className="tp-testi__ava testi-ava-border d-flex align-items-center mb-20 pb-20">
                                                <img src={testavata} alt="testi-avatar" />
                                                <div className="tp-testi__avainfo ml-20">
                                                    <h3 className="tp-testi__title tp-title-meta mt-5">Darrell Steward</h3>
                                                    <i>President of Sales</i>
                                                </div>
                                            </div>
                                            <p>Phosfluorescently aggregate bleeding-edge channels rather than global mindshare. Monotonectally enable enterprise wide strategi theme areas for worldwide initiatives.</p>
                                            <div className="tp-testi__rating mb-5">
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-rs-star" />
                                                <span>4.5</span>
                                            </div>
                                            <div className="testi-quote">
                                                <FaQuoteRight />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tp-test-s-item">
                                        <div className="tp-testi tp-testi-round p-relative">
                                            <div className="tp-testi__ava testi-ava-border d-flex align-items-center mb-20 pb-20">
                                                <img src={testavata} alt="testi-avatar" />
                                                <div className="tp-testi__avainfo ml-20">
                                                    <h3 className="tp-testi__title tp-title-meta mt-5">Darrell Steward</h3>
                                                    <i>President of Sales</i>
                                                </div>
                                            </div>
                                            <p>Phosfluorescently aggregate bleeding-edge channels rather than global mindshare. Monotonectally enable enterprise wide strategi theme areas for worldwide initiatives.</p>
                                            <div className="tp-testi__rating mb-5">
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-ss-star" />
                                                <i className="fi fi-rs-star" />
                                                <span>4.5</span>
                                            </div>
                                            <div className="testi-quote">
                                                <FaQuoteRight />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default About
